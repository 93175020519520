import { alert } from '@reducer/alert';
import { appointmentServices } from '@reducer/appointment';
import { activeGroup, currentPage, genericImages, isItemSearchActive, itemStock, loader, pdpItem, pdpItemStatus, serverError, showServicesCartModal, storesList, whatsappTemplates, languageKeywords,activeLanguage } from '@reducer/common';
import { orderItems } from '@reducer/order';
import { store } from '@reducer/store';
import { user } from '@reducer/user';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  store,
  activeGroup,
  loader,
  alert,
  currentPage,
  pdpItem,
  storesList,
  pdpItemStatus,
  isItemSearchActive,
  user,
  orderItems,
  appointmentServices,
  serverError,
  genericImages,
  whatsappTemplates,
  itemStock,
  showServicesCartModal,
  languageKeywords,
  activeLanguage
});

export default rootReducer;

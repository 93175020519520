export const REPLACE_ORDER_ITEMS = 'REPLACE_ORDER_ITEMS';
export const REMOVE_ORDER_ITEM = 'REMOVE_ORDER_ITEM';

export const GET_ORDER_ITEMS = 'GET_ORDER_ITEMS';
export const SYNC_LOCAL_STORAGE_ORDER = 'SYNC_LOCAL_STORAGE_ORDER';

//Order status availables 
export const ORDER_REQUESTED = "REQUESTED";
export const ORDER_PLACED = "PLACED";
export const ORDER_ACCEPTED = "ACCEPTED";
export const ORDER_REJECTED = "REJECTED";
export const ORDER_COMPLETED = "COMPLETED";
export const ORDER_FIX_DISCOUNT_TYPE = 'FIX_DISCOUNT';
export const ORDER_PERCENTAGE_DISCOUNT_TYPE = 'PERCENTAGE_DISCOUNT';
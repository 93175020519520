export const REPLACE_APPOINTMENT_SERVICES = 'REPLACE_APPOINTMENT_SERVICES';
export const REMOVE_APPOINTMENT_SERVICE = 'REMOVE_APPOINTMENT_SERVICE';

export const GET_APPOINTMENT_SERVICES = 'GET_APPOINTMENT_SERVICES';
export const SYNC_LOCAL_STORAGE_APPOINTMENT = 'SYNC_LOCAL_STORAGE_APPOINTMENT';

export const APPOINTMENT_CREATED = "CREATED";
export const APPOINTMENT_CREATED_FOR_OL_PAYMENT = "PENDING";
export const APPOINTMENT_CONFIRMED = "CONFIRMED";
export const APPOINTMENT_CHECKINED = "CHECKIN";
export const APPOINTMENT_COMPLETED = "COMPLETED";
export const APPOINTMENT_CANCELLED = "CANCELLED";
export const APPOINTMENT_RESHEDULED = "RESHEDULED";
export const APPOINTMENT_TYPE = "ONLINE";

export const DEFAULT_SERVICE_DURATION = 30;
export const APPOINTMENT_SLOT_GAP_IN_MINS = 30;
export const DASHBOARD_APPOINTMENT_SLOT_GAP_IN_MINS = 15;
import React from "react"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)

        // Define a state variable to track whether is an error or not
        this.state = { hasError: false }
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI

        return { hasError: true }
    }
    componentDidCatch(error, errorInfo) {
        // You can use your own error logging service here
        console.log({ error, errorInfo })
    }
    render() {
        const state: any = this.state;
        if (state?.hasError) {
            return (
                <div className="inactive-store-wrapper">
                    <div className="inactive-store-image NF-404-image">
                        <img src={'/assets/Icons/page_not_found.png'} />
                        <div>Oops, there is an error!</div>
                        <button className="primary-btn" type="button" onClick={() => this.setState({ hasError: false })}>Try again?</button>
                    </div>
                </div>
            )
        } else {
            return <>{this.props.children}</>
        }
    }
}

export default ErrorBoundary